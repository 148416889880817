import { fetchProductsList } from "@lib/data"
import usePreviews from "@lib/hooks/use-previews"
import getNumberOfSkeletons from "@lib/util/get-number-of-skeletons"
import repeat from "@lib/util/repeat"
import { StoreGetProductsParams } from "@medusajs/medusa"
import ProductPreview from "@modules/products/components/product-preview"
import SkeletonProductPreview from "@modules/skeletons/components/skeleton-product-preview"
import { useCart } from "medusa-react"
import { useEffect, useMemo } from "react"
import { useInView } from "react-intersection-observer"
import { useInfiniteQuery } from "react-query"
import sortProductsByColor from "@lib/util/product-sort-color"
import sortProductsAdvanced from "@lib/util/product-advanced-sort"

type InfiniteProductsType = {
    params: StoreGetProductsParams
    isRenderItem?: boolean
}

const InfiniteProducts = ({ params, isRenderItem }: InfiniteProductsType) => {
    const { cart } = useCart()

    const { ref, inView } = useInView()

    const queryParams = useMemo(() => {
        const p: StoreGetProductsParams = {}

        if (cart?.id) {
            p.cart_id = cart.id
        }

        p.is_giftcard = false

        return {
            ...p,
            ...params
        }
    }, [cart?.id, params])

    const { data, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage } =
        useInfiniteQuery(
            [
                `infinite-products-store`,
                queryParams,
                cart,
                "v2",
                isRenderItem ?? false
            ],
            ({ pageParam }) => fetchProductsList({ pageParam, queryParams }),
            {
                getNextPageParam: (lastPage) => lastPage.nextPage
            }
        )

    const previews = usePreviews({ pages: data?.pages, region: cart?.region })

    useEffect(() => {
        if (inView && hasNextPage) {
            fetchNextPage()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView, hasNextPage])
    const hit = [2, 4, 8]

    return (
        <div className="flex-1 small:content-container text-slate-800">
            <ul className="grid grid-cols-2 small:grid-cols-3 medium:grid-cols-4 gap-x-4 gap-y-8 flex-1">
                {sortProductsAdvanced(previews).map((p, i) => (
                    <li
                        key={p.id}
                        /*className={clsx({
                            "medium:px-2 medium:col-span-2": hit.includes(i),
                        })}*/
                    >
                        <ProductPreview {...p} doubleColl={false} />
                    </li>
                ))}
                {isLoading &&
                    !previews.length &&
                    repeat(8).map((index) => (
                        <li key={index}>
                            <SkeletonProductPreview />
                        </li>
                    ))}
                {isFetchingNextPage &&
                    repeat(getNumberOfSkeletons(data?.pages)).map((index) => (
                        <li key={index}>
                            <SkeletonProductPreview />
                        </li>
                    ))}
            </ul>
            <div
                className="py-4 small:py-16 flex justify-center items-center text-small-regular text-gray-700"
                ref={ref}
            >
                <span ref={ref}></span>
            </div>
        </div>
    )
}

export default InfiniteProducts
