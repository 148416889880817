import { ProductPreviewType } from "../../types/global"

const sortProductsAdvanced = (products: ProductPreviewType[]) => {
    return products.sort(
        (productA: ProductPreviewType, productB: ProductPreviewType) => {

            const measurementA = parseInt(
                productA?.title
                    ?.match(/\d+/)?.[0] ?? '400');
            const measurementB = parseInt(
                productB?.title
                    ?.match(/\d+/)?.[0] ?? '400');

            if (
                productA.title.match(
                    /geel|zwart|rustiek|walnoot|ebony|naturel/i
                ) === null ||
                productB.title.match(
                    /geel|zwart|rustiek|walnoot|ebony|naturel/i
                ) === null
            )
                return 0
            const colorA =
                productA?.title
                    ?.toLowerCase()
                    ?.match(/geel|zwart|rustiek|walnoot|ebony|naturel/i)?.[0] ??
                "naturel"
            const colorB =
                productB?.title
                    ?.toLowerCase()
                    .match(/geel|zwart|rustiek|walnoot|ebony|naturel/i)?.[0] ??
                "naturel"

            const colorValues: { [key: string]: number } = {
                rustiek: 0,
                walnoot: 1,
                zwart: 2,
                ebony: 3,
                geel: 4,
                naturel: 5
            }

            if (measurementA < measurementB) {
                return -1;
            } else if (measurementA > measurementB) {
                return 1;
            } else {
                if (colorValues[colorA] < colorValues[colorB]) {
                    return -1
                } else if (colorValues[colorA] > colorValues[colorB]) {
                    return 1
                } else {
                    return 0
                }
            }
        }
    )
}

export default sortProductsAdvanced
